import Layout from "../components/Layout"
import {Link} from "gatsby"
import React from "react"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    
    <div className="max-w-1920 mx-auto p-5 lg:p-12">
      <section className="flex flex-col justify-center items-center mt-10 lg:mt-20 ele-style text-center lg:text-xl">
        <h2>Not Found</h2>
        <p>Sorry <span role="img" aria-label="Pensive emoji">😔</span> we couldn’t find what you were looking for.</p>

        <StaticImage layout="constrained" placeholder="none" loading="eager" width={800} className="my-8 mx-auto inline-block" src="../assets/images/tcm50-broken.jpg" alt="broken Talman acoustic guitar" quality={75}  />

        <p><Link to="/">Go to home</Link> <br /><br /> ...or explore different Talman <Link to="/guitars">guitars</Link> and <Link to="/basses">basses</Link></p>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage



